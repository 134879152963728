<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['会员管理']" :chooseCrumbData="2" @close="hide"></bread-crumb>

      <div style="padding: 20px 20px 0; white-space: nowrap">
        <el-row>
          <el-col :span="7">
            <span>日期范围：</span>
            <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px">
          <el-col :span="4">
            <span>会员状态：</span>
            <el-select v-model="option1" clearable filterable placeholder="卡状态" style="width: 100% !important">
              <el-option v-for="(item, index) in option1es" :key="item" :label="item" :value="index">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-select v-model="option2" clearable filterable placeholder="卡等级">
              <el-option v-for="(item, index) in option2es" :key="item" :label="item" :value="index">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-select v-model="option3" clearable filterable placeholder="店铺">
              <el-option v-for="item in option3es" :key="item.id" :label="item.shop_name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-select v-model="option4" clearable filterable placeholder="卡类型">
              <el-option v-for="(item, index) in option4es" :key="item" :label="item" :value="index">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">
            <el-input v-model="QueryContent" placeholder="输入卡号/手机号/地址" style="width: 70%"></el-input>
            <el-button type="success" size="mini" style="margin-left: 5px" @click="searchBtn">
              <div style="display: flex; align-items: center; font-size: 14px">
                <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
                <span style="margin-left: 3px">查询</span>
              </div>
            </el-button>
          </el-col>
        </el-row>
      </div>

      <div style="
          margin: 15px 20px;
          padding: 5px 10px;
          border: 1px solid #eeeeee;
          background-color: #f5fafe;
          display: flex;
          align-items: center;
        ">
        <el-button type="primary" size="mini" @click="isShow = 1">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">新增会员</span>
          </div>
        </el-button>
        <el-button type="danger" size="mini" @click="batchClick">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">批量删除</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini" @click="dialogFormVisible1 = true">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">导入Excel</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini" @click="dialogFormVisible1 = true">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 5px">导出会员</span>
          </div>
        </el-button>
      </div>

      <div style="padding: 10px 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column sortable label="创建日期" prop="create_time">
          </el-table-column>
          <el-table-column prop="card_number" sortable label="卡号">
          </el-table-column>
          <el-table-column prop="member_name" label="姓名"> </el-table-column>
          <el-table-column prop="bind_phone" label="手机"> </el-table-column>
          <el-table-column prop="address" label="地址"> </el-table-column>
          <el-table-column prop="user_type" label="类型">
            <template slot-scope="scope">
              {{ scope.row.user_type == 0 ? "个人" : "企业" }}
            </template>
          </el-table-column>
          <el-table-column prop="level" sortable label="等级">
          </el-table-column>
          <el-table-column prop="card_balance" sortable label="余额">
          </el-table-column>
          <el-table-column prop="gold" sortable label="金币"> </el-table-column>
          <el-table-column prop="consumption_monery" sortable label="累计消费">
          </el-table-column>
          <el-table-column prop="referrer.member_name" label="推荐人">
          </el-table-column>
          <el-table-column prop="shop_id.shop_name" label="所属门店">
          </el-table-column>
          <el-table-column label="卡状态">
            <template slot-scope="scope">
              {{ scope.row.card_status == 0 ? "未激活" : "已激活" }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                ">
                <el-button plain @click="infoBtn(scope.row)">详情</el-button>
                <el-button plain style="margin: 5px 0" @click="handleRedact(scope.row)">编辑</el-button>
                <el-button plain @click="handleReset(scope.row)">重置密码</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style="">显示第&nbsp;{{
            tableData.length == 0 ? 0 : (page - 1) * length + 1
          }}&nbsp;至&nbsp;{{
  (page - 1) * length + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>

          <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
            <el-button @click="homePage">首页</el-button>
            <el-button @click="upPage">上页</el-button>
            <el-pagination background layout=" pager,slot" :total="tableFinshNum" :current-page="page"
              @current-change="currentChange"></el-pagination>
            <el-button @click="nextPage">下页</el-button>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <add-group v-if="isShow == 1" @close="onClose"></add-group>
    <memberInfor v-if="isShow == 2" @close="onClose" :data="data" />
    <memberEdit v-if="isShow == 3" @close="onClose" :data="data" />
  </div>
</template>
<script>
import addGroup from "./childrens/memberAddGroup.vue";
import { getTime } from "../../../../until/getTime";
import memberInfor from "./childrens/memberInfo.vue";
import memberEdit from "./childrens/memberEdit.vue";
export default {
  components: {
    addGroup,
    memberInfor,
    memberEdit,
  },
  props: ['close'],

  data() {
    return {
      hide: this.close || this.onClose,
      date: "",

      option1: "",
      option1es: ["未激活", "已激活", "已停用"],
      option2: "",
      option2es: ["vip0", "vip1", "vip2", "vip3", "vip4", "vip5"],
      option3: "",
      option3es: "",
      option4: "",
      option4es: ["会员", "推广员", "代理商"],
      QueryContent: "",

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
      page: 1,
      length: 10,
      commercial_id: "",
      isShow: 0,
      data: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
    this.$http
      .post("/system/shop/lst", {
        commercial_id: this.commercial_id,
      })
      .then((res) => {
        this.option3es = res.data.data;
      });
  },
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    infoBtn(row) {
      this.isShow = 2;
      this.data = row;
    },
    getTabList() {
      this.$http
        .post("/client/member/lst", {
          currentPage: this.page,
          currentLength: this.length,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleDetails() { },
    handleRedact(row) {
      this.data = row;
      this.isShow = 3;
    },
    batchClick() {
      if (this.multipleSelection.length == 0) return;
      this.$confirm("确认删除选中的会员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const idArr = this.multipleSelection.map((item) => {
            return item.member_id;
          });
          this.$http
            .post("/Client/member/bacthDel", {
              idArr,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onAdd() {
      this.isShow = false;
    },
    onClose() {
      this.isShow = 0;
      this.getTabList();
    },
    handleReset(row) {
      this.$confirm("确认重置密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Client/member/resetPwd", {
              id: row.member_id,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    searchBtn() {
      this.$http
        .post("/Client/member/search", {
          firstTime: this.date[0] ? getTime(this.date[0]) : "",
          lastTime: this.date[1] ? getTime(this.date[1]) : "",
          status: this.option1,
          level: this.option2,
          shop_id: this.option3,
          type: this.option4,
          search: this.QueryContent,
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page <= 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page++;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabList();
      },
    },
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}
</style>
