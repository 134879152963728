<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>会员资料</el-breadcrumb-item>
        <el-breadcrumb-item>会员详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>
    <div class="box">
      <Header
        @headerChange="headerChange"
        :headers="headers"
        style="margin-bottom: 20px"
      />
      <el-form
        :model="data"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        v-if="header == '基本信息'"
      >
        <el-form-item label="会员卡号：">
          <div>{{ data.card_number }}</div>
        </el-form-item>
        <div class="row">
          <el-form-item label="会员名称：" required>
            <div>{{ data.member_name }}</div>
          </el-form-item>
          <el-form-item label="绑定手机：" required>
            <div>{{ data.bind_phone }}</div>
          </el-form-item>
        </div>
        <el-form-item label="所在地：" required>
          <div>{{ data.location }}</div>
        </el-form-item>
        <el-form-item label="详细地址：" required>
          <div>{{ data.address }}</div>
        </el-form-item>
        <div class="row">
          <el-form-item label="所属门店：" required>
            <div>
              {{ data.shop_id.shop_name }}
            </div>
          </el-form-item>
          <el-form-item label="推荐人：">
            <div>{{ data.referrer.member_name }}</div>
          </el-form-item>
        </div>
        <el-form-item label="卡类型：" required>
          <el-radio v-model="radio" label="1" v-if="data.card_type == 0"
            >会员</el-radio
          >
          <el-radio v-model="radio" label="1" v-if="data.card_type == 1"
            >推广员</el-radio
          >
          <el-radio v-model="radio" label="1" v-if="data.card_type == 2"
            >代理商</el-radio
          >
        </el-form-item>
        <el-form-item label="卡等级：" required>
          <el-radio v-model="radio" label="1">{{ data.level }}</el-radio>
        </el-form-item>
        <el-form-item label="用户类型：" required>
          <el-radio v-model="radio" label="1">{{
            data.user_type == 0 ? "个人" : "企业"
          }}</el-radio>
        </el-form-item>
        <el-form-item label="支付方式：" required>
          <el-radio v-model="radio" label="1">{{
            data.pay_method == 0 ? "现结" : "记账"
          }}</el-radio>
        </el-form-item>
        <el-form-item label="备注：">
          <div>{{ data.remake }}</div>
        </el-form-item>
        <div class="row">
          <el-form-item label="卡内余额：">
            <div>{{ data.card_balance }}</div>
          </el-form-item>
          <el-form-item label="卡内金币：">
            <div>{{ data.card_gold }}</div>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="当前欠款：">
            <div>{{ data.current_debt }}</div>
          </el-form-item>
          <el-form-item label="当前欠票：">
            <div>{{ data.current_bill }}</div>
          </el-form-item>
        </div>
      </el-form>
      <div v-if="header == '消费记录' || header == '推广记录'">
        <el-table
          :data="tabData"
          style="width: 100%"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="name" label="创建日期"> </el-table-column>
          <el-table-column prop="name" label="订单编号"> </el-table-column>
          <el-table-column prop="name" label="收货人"> </el-table-column>
          <el-table-column prop="name" label="联系人"> </el-table-column>
          <el-table-column prop="name" label="地区"> </el-table-column>
          <el-table-column prop="name" label="地址"> </el-table-column>
          <el-table-column prop="name" label="计划配送时间"> </el-table-column>
          <el-table-column prop="name" label="订单数量"> </el-table-column>
          <el-table-column prop="name" label="应收金额"> </el-table-column>
          <el-table-column prop="name" label="订单状态"> </el-table-column>
          <el-table-column prop="name" label="操作"> </el-table-column>
        </el-table>
      </div>
      <el-table
        :data="tabData1"
        style="width: 100%"
        border
        v-if="header == '账单记录' || header == '金币记录'"
      >
        <el-table-column prop="name" label="日期" sortable> </el-table-column>
        <el-table-column prop="name" label="摘要" sortable> </el-table-column>
        <el-table-column prop="name" label="收入" sortable> </el-table-column>
        <el-table-column prop="name" label="支出" sortable> </el-table-column>
        <el-table-column prop="name" label="结余" sortable> </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import Header from "./header.vue";
export default {
  props: ["data"],
  components: { Header },
  data() {
    return {
      headers: ["基本信息", "消费记录", "推广记录", "账单记录", "金币记录"],
      header: "基本信息",
      radio: "1",
      tabData: [{}],
      tabData1: [],
      multipleSelection: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    closeClick() {
      this.$emit("close");
    },
    headerChange(item) {
      this.header = item;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box {
  margin: 20px 30px 0;
  .demo-ruleForm {
    margin: 20px 80px 0;
    .row {
      display: flex;
      justify-content: space-between;
      padding-right: 200px;
    }
  }
}
</style>
